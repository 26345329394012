import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import Modal from "../components/elements/Modal";
import Image from "../components/elements/Image";
import Input from "../components/elements/Input";
import Button from "../components/elements/Button";
import ReactGA from "react-ga";
import Config from '../Config';
const axios = require('axios').default;


class Home extends React.Component {

    state = {
        signupModalActive: false,
        submittingEmail: false,
        email: '',
        emailSent: false,
        lastEmail: '',
    }

    openModal = (source) => {
        this.setState({ signupModalActive: true });
        ReactGA.pageview('/show-signup-' + source);
    }

    closeModal = (e) => {
        e.preventDefault();
        this.setState({ signupModalActive: false, emailSent: false });
        ReactGA.pageview('/close-signup');
    }

    submitEmail = async () => {
        if (this.state.email.length === 0) {
            return;
        }
        ReactGA.pageview('/submit-signup');

        this.setState({ submittingEmail: true });

        const json = JSON.stringify({ email: this.state.email, website: Config.website_name });
        const res = await axios.post('https://2yz5z3af1g.execute-api.us-east-1.amazonaws.com/api/signup', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.setState({ submittingEmail: false, emailSent: true, email: '', lastEmail: this.state.email });
    }

    setEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    onEmailKeyUp = (e) => {
        if (e.key === "Enter") {
            this.submitEmail();
        }
    }


    render() {
    return (
      <React.Fragment>
        <HeroSplit imageFill className="illustration-section-01" onCTA={() => {this.openModal('b1')}} />
        <FeaturesTiles />
          <Cta topDivider bottomDivider split onCTA={() => {this.openModal('b2')}} />
          {/*<FeaturesSplit invertMobile imageFill className="illustration-section-05" />
        <Pricing pricingSwitcher className="illustration-section-03" />
        */}

          <Modal
              id="signup-modal"
              show={this.state.signupModalActive}
              handleClose={this.closeModal}>
              <div className="center-content">
                  <Image
                      className="mb-16"
                      src={require('./../assets/images/pricing-icon.svg')}
                      alt="Diamond"
                      width={53.2}
                      height={56} />
                  <h3 className="mt-0 mb-12">Sign Up for Early Access</h3>
                  <p className="text-sm">
                      We're currently working with select websites - please leave your email address and we'll get back to you as soon as possible.
                  </p>
              </div>
              {this.state.emailSent ?
                  <p className="text-sm">Thanks! We'll get back to the following email address: <b>{this.state.lastEmail}</b></p>
                  :
                  <Input
                      type="email"
                      label="Email Address"
                      placeholder="Email Address"
                      onChange={this.setEmail}
                      onKeyUp={this.onEmailKeyUp}
                      formGroup="desktop"
                      labelHidden>
                      <Button color="primary" loading={this.state.submittingEmail}
                              onClick={this.submitEmail}>Submit</Button>
                  </Input>
              }
              <div className="center-content mt-24">
                  <a
                      className="func-link text-xxs fw-500 tt-u"
                      aria-label="close"
                      href="#0"
                      onClick={this.closeModal}
                  >{ this.state.emailSent ? 'Close' : 'Cancel' }</a>
              </div>
          </Modal>
      </React.Fragment>
    );
  }
}

export default Home;
