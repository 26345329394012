export default {
    website_name: 'WindowShopper',
    texts: {
        heading: 'Tinder-like Shopping for Your Store',
        screenshot: require('./assets/images/swipe_to_like.gif'),
        subtitle: 'Increase sales and AOV by letting shoppers browse through your store with a swipe-to-like interface, which creates a shoppable wish list.',
        cta1: 'Get Started',
        cta_title: 'Get a better shopping experience for your store.',
        cta2: 'Get Started',
        features: [
            { name: 'Increase Conversion Rate', icon: require('./assets/images/feature-tile-icon-01.svg'), description: 'Our algorithms will show the customer more of the products they\'re likely to buy.' },
            { name: 'Increase AOV', icon: require('./assets/images/feature-tile-icon-02.svg'), description: 'Increase Average Order Value - everything your customer likes will be added to their cart or wish list.' },
            { name: 'Get More Products Discovered', icon: require('./assets/images/feature-tile-icon-03.svg'), description: 'We\'ll help the customer see much more of your product catalog, more than they would normally ever see.' },
            { name: 'Discover Which Products Are More Well Liked', icon: require('./assets/images/feature-tile-icon-04.svg'), description: 'Get detailed reports which products were liked or disliked by your customers, so you can better optimize your catalog.' },
            { name: 'Allow Easy Sharing', icon: require('./assets/images/feature-tile-icon-05.svg'), description: 'Customers can easily share their liked products wish list with their friends.' },
            { name: 'Unique Shopping Experience', icon: require('./assets/images/feature-tile-icon-06.svg'), description: 'A unique window shopping experience, something that was only available in physical stores until now.' },
        ]
    },
    ga: {
        trackingId: 'UA-186289791-1',
        debug: true,
        gaOptions: {
            cookieDomain: 'none'
        }
    }
};
