import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import PropTypes from 'prop-types';
import Config from '../../Config';

const propTypes = {
  ...SectionSplitProps.types,
  onCTA: PropTypes.func
}

const defaultProps = {
  ...SectionSplitProps.defaults,
  onCTA: null
}

class HeroSplit extends React.Component {
  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      onCTA,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
                <div className="hero-content split-item-content center-content-mobile">
                  <h4 className="mt-0 mb-16 reveal-from-left">
                    <i>{Config.website_name}</i>
                  </h4>
                  <h1 className="mt-0 mb-16 reveal-from-left">
                    {Config.texts.heading}
                  </h1>
                  <p className="mt-0 mb-24 reveal-from-left" data-reveal-delay="200">
                    {Config.texts.subtitle}
                  </p>
                  <div className="reveal-from-left" data-reveal-delay="400">
                    <Button tag="a" color="primary" wideMobile
                            onClick={onCTA}
                    >
                      {Config.texts.cta1}
                    </Button>
                  </div>
                </div>
                <div className="hero-figure split-item-image illustration-element-01 reveal-scale-up">
                  <Image
                    className="has-shadow"
                    src={Config.texts.screenshot}
                    alt="Hero"
                    width={528}
                    height={396} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;
